export const defaultEnvironment = {
  firebase: {
    apiKey: "AIzaSyCvAEyfpUXEVAt9xgMlZ4fAjHkSLu80mWs",
    authDomain: "knowledgebase-ed3bf.firebaseapp.com",
    projectId: "knowledgebase-ed3bf",
    storageBucket: "knowledgebase-ed3bf.appspot.com",
    messagingSenderId: "369498616002",
    appId: "1:369498616002:web:542a55c7bf0c17f7eccb5b",
    locationId: 'asia-northeast1',
    measurementId: "G-7BCXD6S541"
  },
  production: false,
  domain: "https://contract-c640d.web.app",
  clientDomain: 'https://knowledgebase-ed3bf.web.app',
  useEmulators: false,
  functions: {
    space: {
      create: 'space-create'
    }
  }
};
