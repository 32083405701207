import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './start/start.component';
import { AfterSendEmailVerificationComponent } from './after-send-email-verification/after-send-email-verification.component';
import { AfterSignInGoogleVerificationComponent } from './after-sign-in-google-verification/after-sign-in-google-verification.component';


const routes: Routes = [
  {path: '', component: StartComponent},
  {path: 'after-send-email-verification', component: AfterSendEmailVerificationComponent },
  {path: 'after-sign-in-google-verification', component: AfterSignInGoogleVerificationComponent },
  //{path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
