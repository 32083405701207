<div class="container">
    <h1 class="head">mebaLのスペースを新規作成</h1>
    <p>※ご利用開始月を含む３か月間<br>
        無料ですべての機能が利用可能です</p>
    <form [formGroup]="spaceSettingForm">
        <mat-form-field>
            <mat-label>スペース名</mat-label>
            <input type="text" matInput placeholder="株式会社mebaL" formControlName="name">
            <mat-error *ngIf="spaceSettingForm.controls.name.hasError('required')">必須入力です。</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>電話番号</mat-label>
            <input type="tel" matInput formControlName="telephone">
            <mat-error *ngIf="spaceSettingForm.controls.telephone.hasError('required')">必須入力です。</mat-error>
            <mat-error *ngIf="spaceSettingForm.controls.telephone.hasError('pattern') ">電話番号を入力してください。</mat-error>
        </mat-form-field>
        <mat-checkbox labelPosition="after" formControlName="accepted"><a href="https://mebal.jp/terms/">利用規約</a>及び<a href="https://mebal.jp/datapolicy/">データ取扱いポリシー</a>に同意しますか。</mat-checkbox>
    </form>
    <div class="loginStyleSetting" [ngSwitch]="loginStyleSetting">
        <ng-container *ngSwitchCase="'not-setting'">
            <button mat-raised-button class="btn" color="accent"
                (click)="onClickCreateUserWithEmailAndPassword()">Emailアドレスで作成</button>
            <hr class="hr-text" data-content="or">
            <button mat-button class="btn" (click)="onClickSignInWithGoogle()" [disabled]="spaceSettingForm.invalid">
                <img *ngIf="spaceSettingForm.invalid" src="assets/images/btn_google_signin_light_disabled_web.png">
                <img *ngIf="spaceSettingForm.valid" src="assets/images/btn_google_signin_light_normal_web.png">
            </button>
        </ng-container>
        <ng-container *ngSwitchCase="'email-and-password'">
            <form [formGroup]="emailSignInForm">
                <mat-form-field>
                    <mat-label>名前</mat-label>
                    <input type="text" matInput placeholder="mebaL 太郎" formControlName="name">
                    <mat-error *ngIf="emailSignInForm.controls.name.hasError('required')">必須入力です。</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>メールアドレス</mat-label>
                    <input type="email" matInput placeholder="xxxxx@mebal.jp" formControlName="email">
                    <mat-error
                        *ngIf="emailSignInForm.controls.email.hasError('email') && !emailSignInForm.controls.email.hasError('required')">
                        有効なEmailアドレスを入力してください。
                    </mat-error>
                    <mat-error *ngIf="emailSignInForm.controls.email.hasError('required')">必須入力です。</mat-error>
                </mat-form-field>
                <mat-form-field class="password-form">
                    <mat-label>パスワード</mat-label>
                    <input type="password" matInput formControlName="password">
                    <mat-error
                        *ngIf="emailSignInForm.controls.password.hasError('pattern') && !emailSignInForm.controls.password.hasError('required')">
                        半角英小文字大文字数字をそれぞれ含むパスワードを、６文字以上２０文字以下で設定してください。 例）AbCd1234
                    </mat-error>
                    <mat-error *ngIf="emailSignInForm.controls.password.hasError('required')">必須入力です。</mat-error>
                </mat-form-field>
                <button mat-flat-button class="btn" color="accent" [disabled]="spaceSettingForm.invalid || emailSignInForm.invalid" (click)="onClickSubmit()">スペース作成</button>
                <button mat-button class="btn" (click)="onClickBack()">戻る</button>
            </form>
        </ng-container>
        <ng-container *ngSwitchCase="'google-login'">
            <p>ポップアップウィンドウの指示にしたがって、ログインしてください。</p>
            <button mat-button class="btn" (click)="onClickBack()">戻る</button>
        </ng-container>
    </div>
</div>