import { Injectable } from '@angular/core';
import { DocumentReference, Firestore, doc, onSnapshot } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User, converterUserModel, isUserModel } from '../_model/user';
import { userDocumentPath } from '@k-bit-modesty/share-models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    /** Firestore */
    private _firestore: Firestore
  ) { }


  /**
   * ユーザー情報取得
   * @param ref ユーザ情報の参照
   * @returns ユーザ情報Observable
   */
  getUserFromRef(ref: DocumentReference): Observable<User|undefined> {
    // 戻り値定義
    const observable = new Observable<User | undefined>(subscriber => {
      // スナップショット取得
      const unsbscribe = onSnapshot(ref.withConverter(converterUserModel), {
        next: async snapshot => {
          // データ型に変換
          const data = snapshot.data({ serverTimestamps: 'estimate' });
          // ユーザーモデルかどうか
          if (isUserModel(data)) {
            // ユーザーモデルを返す
            subscriber.next(new User(ref, data));
          }
          // それ以外
          else {
            // undefinedを返す
            subscriber.next(undefined);
          }

          // データ取得一回でいいのでunsubscribeする
          unsbscribe();
        },
        // エラー時
        error: err => {
          // エラー返却
          subscriber.error(err);
          // データ取得一回でいいのでunsubscribeする
          unsbscribe();
        }
      });
    });

    // 結果返却
    return observable;
  }

  /**
   * ユーザー情報取得
   * @param uid 取得するユーザーの固有ID
   */
  getUserFromUID(uid: string): Observable<User|undefined> {
    return this.getUserFromRef(this.getUserInfoDocRef(uid));
  }

  /**
   * ユーザ情報のドキュメント参照取得
   * @param uid 取得するユーザのID
   * @returns ユーザ情報のドキュメント参照
   */
  getUserInfoDocRef(uid: string): DocumentReference {
    return doc(this._firestore, userDocumentPath(uid));
  }

}
