import { DocumentReference, FirestoreDataConverter } from "@angular/fire/firestore";
import { UserBase } from "@k-bit-modesty/share-models";

/** ユーザデータモデル */
export interface UserModel extends UserBase {
    mainSpace?: DocumentReference
}

/** ユーザデータモデル型ガード */
export function isUserModel(arg: any): arg is UserModel {
    return typeof arg === 'object'
        && typeof arg.name === 'string'
        && typeof arg.photoURL === 'string'
        && typeof arg.uid === 'string'
        && (arg.isFirstLogin === undefined || typeof arg.isFirstLogin === "boolean")
        && (arg.mainSpace === undefined || arg.mainSpace instanceof DocumentReference)
        && ['undefined', 'boolean'].some(t => t === typeof arg.isBanned)
}

/** ユーザデータモデルコンバータ */
export const converterUserModel: FirestoreDataConverter<UserModel> = {
    toFirestore: model => model,
    fromFirestore: (snaphost, options) => {
        const data = snaphost.data();
        if(isUserModel(data)) {
            return data;
        }
        else {
            throw new Error(`Not UserModel: ${snaphost.ref.path}`);
        }
    }
}

/** ユーザデータクラス */
export class User implements UserBase{
    constructor(
        private _ref: DocumentReference,
        private _data: UserModel
    ){}

    get id() { return this._ref.id; }
    get ref() { return this._ref; }
    get name() { return this._data.name; }
    get photoURL() { return this._data.photoURL; }
    get uid() { return this._data.uid; }
    get isFirstLogin() { return this._data.isFirstLogin; }
    get mainSpace() { return this._data.mainSpace; }
    get isBanned() { return this._data.isBanned; }

    /** ユーザデータモデル型へ変換 */
    toUserModel() {
        return <UserModel> {
            name: this.name,
            photoURL: this.photoURL,
            uid: this.uid,
            isFirstLogin: this.isFirstLogin,
            isBanned: this.isBanned
        };
    }
}