import { DocumentReference, FieldValue, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot, SnapshotOptions, Timestamp, getDocFromServer } from "@angular/fire/firestore";
import { IntergrationBase, SpaceBase } from "@k-bit-modesty/share-models";

export class Space implements SpaceBase{
    constructor(
        private _ref: DocumentReference,
        private _id: string,
        private _name: string,
        private _isPublic: boolean,
        private _isPersonal: boolean,
        private _createTime: Date,
        private _ownerUserRef: DocumentReference,
        private _adminUsersRefs: DocumentReference[],
        private _usersRefs: DocumentReference[],
        private _invitedUsersRefs: DocumentReference[],
        private _intergrations: IntergrationBase[],
        private _description: string = '',
        private _autoInviteDomain: string[] = []
    ) {}

    get ref() { return this._ref; }
    get id() { return this._id; }
    get name() { return this._name; }
    get isPublic() { return this._isPublic; }
    get isPersonal() { return this._isPersonal; }
    get createTime() { return this._createTime; }
    get ownerUser() { return this._ownerUserRef; }
    get adminUsers() { return this._adminUsersRefs; }
    get users() { return this._usersRefs; }
    get invitedUsers() { return this._invitedUsersRefs; }
    get intergrations() { return this._intergrations; }
    get description() { return this._description; }
    get autoInviteDomain() { return this._autoInviteDomain; }

    /** コンバータ */
    static converter: FirestoreDataConverter<Space> = {
        fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions) {
            const data = snapshot.data();
            if(!isSpaceModel(data)) {
                throw new Error('SpaceModelでない');
            }

            return new Space(
                snapshot.ref,
                snapshot.id,
                data.name,
                data.isPublic,
                data.isPersonal,
                (data.createTime as Timestamp).toDate(),
                data.ownerUser,
                data.adminUsers,
                data.users,
                data.invitedUsers,
                data.intergrations,
                data.description,
                data.autoInviteDomain
            );
        },
        toFirestore(model: PartialWithFieldValue<Space>) {
            return model;
        }
    }

    /**
     * ドキュメント参照から取得
     * @param ref 取得対象
     * @returns 
     */
    static async getFromRef(ref: DocumentReference) {
        const snap = await getDocFromServer(ref.withConverter(Space.converter));
        return snap.data();
    }
}

/** スペースモデル */
export interface SpaceModel extends SpaceBase {
    createTime: Timestamp | FieldValue;
    ownerUser: DocumentReference;
    adminUsers: DocumentReference[];
    users: DocumentReference[];
    invitedUsers: DocumentReference[];
    bannedUsers?: DocumentReference[];
}

/**
 * SpaceModel型ガード
 * @param arg 検査対象
 * @returns 検査結果
 */
export function isSpaceModel(arg: any): arg is SpaceModel {
    return typeof arg === 'object'
        && typeof arg.name === 'string'
        && typeof arg.isPublic === 'boolean'
        && typeof arg.isPersonal === 'boolean'
        && (arg.createTime instanceof Timestamp || arg.createTime instanceof FieldValue)
        && arg.ownerUser instanceof DocumentReference
        && arg.adminUsers instanceof Array
        && arg.users instanceof Array
        && arg.invitedUsers instanceof Array
        && (typeof arg.description === 'undefined' || typeof arg.description === 'string')
        && arg.intergrations instanceof Array
        && (typeof arg.bannedUsers === 'undefined' || arg.bannedUsers instanceof Array)
        && (typeof arg.autoInviteDomain === 'undefined' || arg.autoInviteDomain instanceof Array)
}
