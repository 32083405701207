import { Component, OnInit } from '@angular/core';
import { Auth, onIdTokenChanged } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-after-sign-in-google-verification',
  templateUrl: './after-sign-in-google-verification.component.html',
  styleUrls: ['./after-sign-in-google-verification.component.css']
})
export class AfterSignInGoogleVerificationComponent implements OnInit {

  constructor(
    /** Auth */
    private _auth: Auth,
    /** ActivatedRoute */
    private _route: ActivatedRoute,
    /** Router */
    private _router: Router
  ) { }

  ngOnInit(): void {
   
  }

  onClickLogout() {
    // サインアウトする
    this._auth.signOut()
      // サインアウトしたら、元のページへ
      .then(() => window.location.href = `${environment.clientDomain}`);
  }
}
