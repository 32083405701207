<div class="container">
    <h1>スペースが作成されました</h1>

    ご登録いただいたメールアドレス宛に<br>
    ３分以内に確認メールが届きます。<br>
    <br>
    メールが届きましたら、記載されたリンクから<br>
    アカウントの確認作業を行ってください。<br>
    確認完了後にmebaLへのログインが可能となります<br>
    <br>
    <button mat-raised-button color="accent" (click)="onClickLogout()">ログイン画面へ</button>
</div>
