import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoadingOverlayService {

  constructor (
        private overlay: Overlay
  ) { }
  
  /** 表示中かどうか */
  isShowing = false;
  
  overlayRef = this.overlay.create({
    hasBackdrop: true,　// 背面を覆うウィンドウ(バックドロップ)表示
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically() // 表示位置を中央に
  });


  
  // ローディング開始
  show() {
    if (this.isShowing === false) {
      this.isShowing = true;
      const componentRef = this.overlayRef.attach(new ComponentPortal(MatProgressSpinner));

      componentRef.instance.mode = 'indeterminate';
    }
  }
  
  // ローディング終了
  close() {
    if(this.isShowing === true) {
      this.overlayRef.detach();
      this.isShowing = false;
    }
  }
  
}
